@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  --n100: #f0f0f0;
  --n200: #b9b9b9;
  --n300: #6a6c79;
  --n400: #393A3E;

  --p300: #526882;
  --p500: #264661;

  --text-color: var(--n100);
  --focus-color: var(--n100);
  --focus-text-color: var(--p500);
  --button-background-color: var(--n300);
  --placeholder-text-color: var(--n200);
  --fallback-color: var(--n200);
}

@media (prefers-color-scheme: light) {
  body {
    --n100: #393A3E;
    --n200: #6a6c79;
    --n300: #b9b9b9;
    --n400: #efefef;

    --p300: #a4b3c4;
    --p500: #3d6f9d;

    --focus-color: var(--n100);
    --focus-text-color: var(--n300);
  }
}

body {
  background: var(--n400) no-repeat fixed;
  background-size: cover;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  display: block;
}

body {
  margin: 0;
}
.style_navigationLink__kNVDC {
    color: var(--n100);
    font-family: inter,sans-serif;
    font-size: 24px;
    border-bottom: 4px solid transparent;
}

.style_current__VlLG3 {
    border-bottom: 4px solid var(--n100);
}

.style_navigationRow__tu7XB {
    background: var(--n400);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transition: background-color 300ms linear;
    z-index: 1;
}

.style_offsetNavigationRow__36Qlm {
    .style_nav__EU2UI {
        background: var(--p300);
    }
    .style_navigationLink__kNVDC {
        color: var(--text-color);
    }
    .style_current__VlLG3 {
        border-bottom: 4px solid var(--n100);
    }
}

.style_nav__EU2UI {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 20px;

}

.style_navSmall__bzHN4 {
    flex: 1 1 auto;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: auto;
    display: none;
}

.style_navSmallToggle___xBfM {
    right: 8px;
    top: 8px;
    cursor: pointer;
    z-index: 21;
    position: fixed;
    max-width: 28px;
    color: var(--n100);
}

.style_sidebar__oWsjO {
    flex: 0 0 200px;
    flex-direction: column;
    -webkit-backdrop-filter: blur(30px) saturate(2);
            backdrop-filter: blur(30px) saturate(2);
    padding-inline: 0;
    padding-block: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
    height: 100%;
    width: 100vw;
    top:0;
    padding: 12px;
    left: 0;
    position: fixed;
    z-index: 20;
    gap: 8px;
    display: flex;
    .style_navigationLink__kNVDC {
        width: 80vw;
    }
}

.style_sidebarClosed__hxoYD {
    margin-left: 100vw;
}

@media (max-width: 500px) {
    .style_nav__EU2UI {
        display: none;
    }
    .style_navSmall__bzHN4 {
        display: block;
    }
    .style_navigationRow__tu7XB {
        position: inherit;
        padding: 16px;
    }
}
.style_body__m3Lbr {
    margin-left: auto;
    margin-right: auto;
    max-width: 1800px;
    border-radius: 4px;
}

