.navigationLink {
    color: var(--n100);
    font-family: inter,sans-serif;
    font-size: 24px;
    border-bottom: 4px solid transparent;
}

.current {
    border-bottom: 4px solid var(--n100);
}

.navigationRow {
    background: var(--n400);
    position: sticky;
    top: 0;
    transition: background-color 300ms linear;
    z-index: 1;
}

.offsetNavigationRow {
    .nav {
        background: var(--p300);
    }
    .navigationLink {
        color: var(--text-color);
    }
    .current {
        border-bottom: 4px solid var(--n100);
    }
}

.nav {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 20px;

}

.navSmall {
    flex: 1 1 auto;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: auto;
    display: none;
}

.navSmallToggle {
    right: 8px;
    top: 8px;
    cursor: pointer;
    z-index: 21;
    position: fixed;
    max-width: 28px;
    color: var(--n100);
}

.sidebar {
    flex: 0 0 200px;
    flex-direction: column;
    backdrop-filter: blur(30px) saturate(2);
    padding-inline: 0;
    padding-block: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
    height: 100%;
    width: 100vw;
    top:0;
    padding: 12px;
    left: 0;
    position: fixed;
    z-index: 20;
    gap: 8px;
    display: flex;
    .navigationLink {
        width: 80vw;
    }
}

.sidebarClosed {
    margin-left: 100vw;
}

@media (max-width: 500px) {
    .nav {
        display: none;
    }
    .navSmall {
        display: block;
    }
    .navigationRow {
        position: inherit;
        padding: 16px;
    }
}